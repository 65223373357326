import { createAction, createActionGroup, props } from '@ngrx/store';
import { Objective } from 'src/core/interfaces/objective.interface';
import {
  NGRXObjectiveSource,
  NGRXObjectiveType,
} from './objectives-action.types';
import { Resource } from 'src/core/interfaces/user/goal.interface';

export const ObjectivesActions = createActionGroup({
  source: NGRXObjectiveSource.Objective,
  events: {
    [NGRXObjectiveType.loadFromDB]: props<{
      objectives: Objective[];
    }>(),

    [NGRXObjectiveType.add]: props<{
      objective: Objective;
    }>(),
    [NGRXObjectiveType.addMany]: props<{
      objectives: Objective[];
    }>(),
    [NGRXObjectiveType.addResources]: props<{
      entityId: string;
      resources: Resource[];
    }>(),
    [NGRXObjectiveType.removeResource]: props<{
      entityId: string;
      resourceId: string;
    }>(),
    [NGRXObjectiveType.update]: props<{
      updatedObjective: Objective;
    }>(),
    [NGRXObjectiveType.updateField]: props<{
      entityId: string;
      updatedField: {
        field: string;
        value: unknown;
      };
    }>(),
    [NGRXObjectiveType.deleteEntity]: props<{
      entityId: string;
    }>(),

    [NGRXObjectiveType.removeContributor]: props<{
      entityId: string;
      userId: string;
    }>(),
  },
});

export const initObjectivesStore = createAction(NGRXObjectiveType.initStore);
export const removeAllObjectives = createAction(NGRXObjectiveType.logOut);
