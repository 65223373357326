export enum NGRXObjectiveType {
  initStore = 'Init Store',
  loadFromDB = 'Load From DB',

  add = 'Add',
  addMany = 'Add Many',
  addResources = 'Add Resources',
  removeResource = 'Remove Resource',
  update = 'Update',
  updateField = 'Update Field',
  deleteEntity = 'Delete Entity',


  /* Contributors */
  removeContributor = 'Remove Contributor',

  logOut = 'Log Out',
}

export enum NGRXObjectiveSource {
  Objective = 'Objective',
}
